
.titlebanner {
    min-height: 100px;
    width: 100%;
    background-color: #18212A;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.bannerText {
    font-size: 35px;
    font-weight: 200;
    color: white;
    font-family: 'Inter';
    text-align: center;
    vertical-align: middle;
}

.downloadbanner {
    margin-top: 30px;
    min-height: 250px;
    width: 70%;
    background-color: #18212A;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 25px;
    margin-left:auto;
    margin-right: auto;
}

.downloadText {
    font-size: 35px;
    font-weight: 200;
    color: white;
    font-family: 'Inter';
    text-align: center;
    vertical-align: middle;
}
