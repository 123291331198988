.authformcontainer {
    background-color: #0B0D12;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.formcontainer {
    background-color: #18212A;
    display: inline-block;
    border-radius: 25px;
    height: 350px;
    width: 300px;
    padding: 20px;
    margin-top: 30px;
}

.field {
    width: 200px;
    border-width: 0px;
    border-bottom-width: 1px;
    background-color: #18212A;
    color: #CDE1FB;
    font-size: 18px;
}



.error_message {
    color: red;
}

.linktoform {
    color: #CDE1FB;
    font-size: 14px;
    white-space: break-spaces;
    text-align: right;
    width: 100%;
}

.linktoform:hover {
    cursor: pointer;
}

.formbutton {
    background-color: #31485B;
    color: white;
    padding: 10px;
    border-radius: 15px;
    border-width: 0px;
    margin-top: 30px;
    width: auto;
    min-height: 45px;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 300;
    margin-left: auto;
    margin-right: 35%;
}

.formbutton:hover {
    cursor: pointer;
}