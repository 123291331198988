.App {
  text-align: center;
  height: 100%;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.topnav {
  position: fixed;
  width: 100%;
  background-color: #0B0D12;
  overflow: hidden;
  display: inline-flex;
}

.topnav a {
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  padding: 10px 14px;
  padding-top: 12px;
  font-weight: 400;
  border-radius: 15px;
}

.topnav a:hover {
  background-color: #18212A;
}

.topnav a.active {
  background-color: #0B0D12;
}

.topnav a.rightHeader {
  float: right;
  margin-left: auto;
  margin-right: 10px;
  font-size: 35px;
  border-radius: 15px;
}

.topnav div.title {
  float: left;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  padding: 10px 14px;
  padding-top: 14px;
  font-family: 'Inter';
  font-weight: 300;
  margin-left: 10px;
}

.topnav a.leftHeader {
  float: left;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  padding: 10px 14px;
  padding-top: 14px;
  font-family: 'Inter';
  font-weight: 300;
  margin-left: 30px;
}

.pageContainer {
  padding-top: 50px;
  height: 100%;
}

.footer {
  height: auto;
  width: 100%;
  background-color: #0B0D12;
  display: inline-flex;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
