.UserPanelContainer {
    height: 100%;
    width: 100%;
}

.usernameContainer {
    min-height: 100px;
    width: 100%;
    background-color: #4a7673;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usernameText {
    font-size: 30px;
    color: white;
    font-weight: 300;
}

.logoutbutton {
    background-color: #3b7a75;
    color: white;
    border-width: 0px;
    height: 100%;
    width: 100%;
    font-size: 25px;
    font-weight: 100;
    padding: 10px;
}

.logoutbutton:hover {
    cursor: pointer;
    background-color: #18534f;
}

.rowContainer {
    min-height: 150px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 0.8fr 0.8fr;
}

.functionalitiesContainer {
    flex: 2;
    height: 100%;
    background-color: #698c89;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.subscriptionManagementContainer {
    background-color: #226D68;
    flex: 0.8;
    height: 100%;
}



.logoutContainer {
    flex: 1;
    height: 100%;
    background-color: #3b7a75;
    display: inline-block;
}


.icon {
    color: white;
    font-size: 25px;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.iconlabel {
    font-size: 18px;
    display: inline-block;
    margin-left: 10px;
}

.field {
    width: 200px;
    border-width: 0px;
    border-bottom-width: 1px;
    background-color: #18212A;
    color: #CDE1FB;
    font-size: 18px;
}

.resetbutton {
    background-color: #31485B;
    color: white;
    padding: 10px;
    border-radius: 15px;
    border-width: 0px;
    margin-top: 30px;
    width: auto;
    min-height: 45px;
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 300;
    margin-left: 0px;
}