
.unsubscribe:hover {
    background-color: #58180a;
    cursor: pointer;
}

.subscribe:hover {
    background-color: #182a2a;
    cursor: pointer;
}

.subscriptionButton {
    color: white;
    font-size: 22px;
    font-weight: 300;
    border-width: 0px;
    flex: 1;
    width: 100%;
    font-family: 'Inter';
    border-radius: 15px;
}

.subscribe {
    background-color: #0D161F;
}

.unsubscribe {
    background-color: #0D161F;
}

.downloadbutton {
    font-size: 18px;
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.downloadbutton:hover {
    cursor: pointer;
}